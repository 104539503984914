var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "Fiche praticien" },
                  }),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  active: _vm.loading || _vm.running,
                },
              }),
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-regular" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("div", [
                            _vm._v("Consulter les informations d'un praticien"),
                          ]),
                          _c("v-spacer"),
                          _vm.viewModifButton
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "primary" },
                                  on: { click: _vm.clickOnModeEdition },
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            : _vm._e(),
                          _vm.modeEdition
                            ? _c(
                                "v-row",
                                { attrs: { justify: "end", "no-gutters": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn",
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        disabled: _vm.loading,
                                      },
                                      on: { click: _vm.clickOnCancelEdit },
                                    },
                                    [_vm._v(" Quitter l'édition ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        color: "primary",
                                        disabled: !_vm.canSave,
                                      },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-content-save"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-1" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.practitioners,
                          "search-input": _vm.search,
                          "no-filter": "",
                          clearable: "",
                          "no-data-text": "",
                          loading: _vm.loading || _vm.running,
                          label: "Saisir le nom du praticien",
                          disabled: _vm.disabledSearchPractitioner,
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.search = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.search = $event
                          },
                          input: _vm.selected,
                        },
                        model: {
                          value: _vm.selectedPractitioner,
                          callback: function ($$v) {
                            _vm.selectedPractitioner = $$v
                          },
                          expression: "selectedPractitioner",
                        },
                      }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "inputPrice",
                            attrs: {
                              placeholder: "Ou saisir un Identifiant National",
                              dense: "",
                              clearable: "",
                              disabled: _vm.disabledSearchPractitionerWithIdpp,
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.findWithIdpp.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.idpp,
                              callback: function ($$v) {
                                _vm.idpp = $$v
                              },
                              expression: "idpp",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 px-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled:
                                  _vm.disabledSearchPractitionerWithIdpp,
                              },
                              on: { click: _vm.findWithIdpp },
                            },
                            [_vm._v(" Rechercher ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm.viewMessagePractitionerHasNotAccount
                    ? _c("v-card-text", { staticClass: "pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-h6 font-weight-regular error--text",
                          },
                          [_vm._v(" Ce praticien n'a plus de compte Gsuite. ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.practitioner
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticStyle: { "flex-wrap": "nowrap" },
                                        attrs: {
                                          "no-gutters": "",
                                          align: "center",
                                        },
                                      },
                                      [
                                        !_vm.isModeEdition &&
                                        _vm.exerciseCivility
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-h6 font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.exerciseCivility.view
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c("v-autocomplete", {
                                              staticClass:
                                                "flex-grow-0 flex-shrink-1",
                                              attrs: {
                                                items: _vm.exerciseCivilities,
                                                "item-text": "name",
                                                "return-object": "",
                                              },
                                              model: {
                                                value: _vm.exerciseCivility,
                                                callback: function ($$v) {
                                                  _vm.exerciseCivility = $$v
                                                },
                                                expression: "exerciseCivility",
                                              },
                                            }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-h6 font-weight-bold mx-4",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.firstname) +
                                                " " +
                                                _vm._s(_vm.name) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        !_vm.isModeEdition
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-h6 font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(_vm.gender) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _c("v-autocomplete", {
                                              staticClass:
                                                "flex-grow-0 flex-shrink-1",
                                              attrs: { items: _vm.genders },
                                              model: {
                                                value: _vm.gender,
                                                callback: function ($$v) {
                                                  _vm.gender = $$v
                                                },
                                                expression: "gender",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "capitalize font-weight-regular",
                                            },
                                            [
                                              _vm._v(
                                                " affiliation au groupe : "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-col", { attrs: { cols: "9" } }, [
                                          _c("div", { staticClass: "my-2" }, [
                                            _vm._v(_vm._s(_vm.type)),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "my-2 capitalize font-weight-regular",
                                            },
                                            [_vm._v(" numéro RPPS : ")]
                                          ),
                                        ]),
                                        _c("v-col", { attrs: { cols: "9" } }, [
                                          _c("div", { staticClass: "my-2" }, [
                                            _vm._v(
                                              _vm._s(_vm.practitionerIdpp)
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 capitalize font-weight-regular",
                                          },
                                          [
                                            _vm._v(
                                              " présentation sur le site Avec : "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          align: "center",
                                        },
                                      },
                                      [
                                        !_vm.isModeEdition
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-2 mb-6" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.presentation) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c("v-textarea", {
                                              staticClass: "mb-6",
                                              attrs: {
                                                filled: "",
                                                placeholder:
                                                  "Présentation sur le site Avec",
                                              },
                                              model: {
                                                value: _vm.presentation,
                                                callback: function ($$v) {
                                                  _vm.presentation = $$v
                                                },
                                                expression: "presentation",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.canEditRole,
                                      expression: "canEditRole",
                                    },
                                  ],
                                  attrs: { cols: "3", align: "end" },
                                },
                                [
                                  _vm.practitionerIsCollaborater
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2 px-4 btn",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: _vm.onPractitionerOutOfGroup,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Sortir le praticien du groupe "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.practitionerIsBlacklisted
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2 px-4 btn",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: _vm.onPractitionerUNBlaclist,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Dé-blacklister le praticien "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.practitionerIsExternal
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2 px-4 btn",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: _vm.onPractitionerBlaclist,
                                          },
                                        },
                                        [_vm._v(" Blacklister le praticien ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner && _vm.practitionerIsCollaborater
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "capitalize text-h6 font-weight-regular",
                                },
                                [_vm._v(" Les données groupe : ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-2 capitalize font-weight-regular",
                                  },
                                  [_vm._v("email :")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.email)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-2 capitalize font-weight-regular",
                                  },
                                  [_vm._v(" établissement de rattachement : ")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.establishment)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-2 capitalize font-weight-regular",
                                  },
                                  [_vm._v(" activité : ")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.activity)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-2 capitalize font-weight-regular",
                                  },
                                  [_vm._v(" fonction : ")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.primaryFunction)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-2 capitalize font-weight-regular",
                                  },
                                  [_vm._v(" fonction(s) secondaire(s) : ")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.secondaryFunction)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "my-2 capitalize font-weight-regular",
                                  },
                                  [_vm._v(" titre employé : ")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.employeeTitle)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "capitalize font-weight-regular",
                                  },
                                  [_vm._v("Profession :")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  !_vm.isModeEdition
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "my-2 text-h6 font-weight-regular",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.professionName) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c("v-autocomplete", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-1",
                                        attrs: {
                                          placeholder:
                                            "Sélectionner un nom de profession",
                                          "item-text": "label",
                                          items: _vm.professionsNames,
                                          "no-data-text":
                                            "aucun nom de profession",
                                          clearable: "",
                                          "return-object": "",
                                        },
                                        on: {
                                          input:
                                            _vm.selectedProfessionNameAction,
                                        },
                                        model: {
                                          value: _vm.selectedProfessionName,
                                          callback: function ($$v) {
                                            _vm.selectedProfessionName = $$v
                                          },
                                          expression: "selectedProfessionName",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "capitalize font-weight-regular",
                                  },
                                  [_vm._v(" Profession médicale : ")]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(_vm._s(_vm.medicalProfession.label)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-2",
                              attrs: { "no-gutters": "", align: "center" },
                            },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "capitalize font-weight-regular",
                                  },
                                  [_vm._v(" Spécialité médicale : ")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  !_vm.isModeEdition
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "my-2 font-weight-regular",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.medicalSpeciality.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c("v-autocomplete", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-1",
                                        attrs: {
                                          placeholder: "",
                                          "item-text": "name",
                                          items: _vm.medicalSpecialities,
                                          "no-data-text":
                                            "aucune spécialité médicale",
                                          disabled:
                                            _vm.disableComboMedicalSpeciality,
                                          rules: [
                                            _vm.rules.validateMedicalSpeciality,
                                          ],
                                          "return-object": "",
                                        },
                                        model: {
                                          value: _vm.selectedMedicalSpeciality,
                                          callback: function ($$v) {
                                            _vm.selectedMedicalSpeciality = $$v
                                          },
                                          expression:
                                            "selectedMedicalSpeciality",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner
                    ? _c(
                        "v-card-text",
                        [
                          _vm.isModeEdition
                            ? _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "capitalize font-weight-regular",
                                      },
                                      [_vm._v(" Ajouter un lieu d'exercice : ")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-1",
                                        attrs: {
                                          placeholder:
                                            "Sélectionner un lieux d'exercice",
                                          "item-text": "name",
                                          "item-value": "name",
                                          items: _vm.availablesPracticesPlaces,
                                          "no-data-text":
                                            "aucuns lieux d'exercice",
                                          "return-object": "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.selectedPracticePlace,
                                          callback: function ($$v) {
                                            _vm.selectedPracticePlace = $$v
                                          },
                                          expression: "selectedPracticePlace",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            disabled: !_vm.canAddPracticePlace,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addPracticePlace()
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "capitalize text-h6 font-weight-regular",
                                        },
                                        [_vm._v(" Les lieux d'exercice : ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mt-6", attrs: { cols: "9" } },
                                _vm._l(_vm.practicesPlaces, function (pp, i) {
                                  return _c(
                                    "div",
                                    { key: i },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "capitalize font-weight-regular",
                                                },
                                                [_vm._v("Nom :")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "9" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-subtitle-1 font-weight-bold",
                                                },
                                                [_vm._v(_vm._s(pp.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-thin font-italic",
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        pp.practicePlaceId
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.viewDeletePracticePlace
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "1" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deletePracticePlace(
                                                            pp
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-window-close"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "2",
                                                "align-self": "start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "capitalize font-weight-regular",
                                                },
                                                [_vm._v(" Adresse : ")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "10" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.addressOfPracticePlace(
                                                          pp
                                                        ).address
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.addressOfPracticePlace(
                                                          pp
                                                        ).postalCode
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.addressOfPracticePlace(
                                                          pp
                                                        ).city
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "2",
                                                "align-self": "start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "capitalize font-weight-regular",
                                                },
                                                [_vm._v(" Téléphone : ")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "10" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-regular",
                                                },
                                                [_vm._v(_vm._s(pp.phone))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.viewUrlWebSite(pp)
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "my-4",
                                              attrs: {
                                                "no-gutters": "",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "2",
                                                    "align-self": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "capitalize font-weight-regular",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Lien du site Avec : "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "10" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "btn pl-0",
                                                      attrs: {
                                                        text: "",
                                                        color: "primary",
                                                        loading: _vm.running,
                                                        link: "",
                                                        href: _vm.urlSlugPractitionner(
                                                          pp
                                                        ),
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.slug(pp)) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.viewYesdocAgendasForPracticePlace(pp)
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "my-4",
                                              attrs: {
                                                "no-gutters": "",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "2",
                                                    "align-self": "start",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "capitalize font-weight-regular",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " agendas YESDOC : "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "10" } },
                                                [
                                                  _vm._l(
                                                    _vm.yesdocAgendaOfPracticePlace(
                                                      pp
                                                    ),
                                                    function (agenda, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: "A" + i,
                                                          staticClass:
                                                            "font-weight-regular",
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "11",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        agenda
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm.isModeEdition
                                                                ? _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "1",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteYesdocAgenda(
                                                                                  pp,
                                                                                  agenda
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-window-close"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _vm.isModeEdition
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "11",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "L'uuid de l'agenda yesdoc",
                                                                    dense: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.yesdocAgendaToAdd,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.yesdocAgendaToAdd =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "yesdocAgendaToAdd",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    icon: "",
                                                                    disabled:
                                                                      !_vm.canAddYesdocAgenda,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addYesdocAgenda(
                                                                          pp
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      pp.practitionerOnPracticePlace
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "my-4",
                                                  attrs: {
                                                    "no-gutters": "",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "2",
                                                        "align-self": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "capitalize font-weight-regular",
                                                        },
                                                        [_vm._v(" Agrément : ")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "10" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.practitionerOnPracticePlaceOfPracticePlace(
                                                                  pp
                                                                ).agreement
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "my-4",
                                                  attrs: {
                                                    "no-gutters": "",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "2",
                                                        "align-self": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "capitalize font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Dépassement d'honoraire : "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "10" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.practitionerOnPracticePlaceOfPracticePlace(
                                                                  pp
                                                                ).excessFee
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "my-4",
                                                  attrs: {
                                                    "no-gutters": "",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "2",
                                                        "align-self": "start",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "capitalize font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " expertises : "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "10" } },
                                                    _vm._l(
                                                      _vm.practitionerOnPracticePlaceOfPracticePlace(
                                                        pp
                                                      ).expertises,
                                                      function (expertise, i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: "C" + i,
                                                            staticClass:
                                                              "font-weight-regular",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  expertise
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "my-4",
                                                  attrs: {
                                                    "no-gutters": "",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "2",
                                                        "align-self": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "capitalize font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " téléphone secrétariat : "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "10" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-weight-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.practitionerOnPracticePlaceOfPracticePlace(
                                                                  pp
                                                                ).secretariat
                                                                  .phone
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.practicesPlaces.length > 1
                                                ? _c("v-divider", {
                                                    staticClass: "my-4",
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteTableItemDialog", {
        attrs: {
          label:
            "Le lieu d'exercice se trouve au sein de l'établissement de rattachement du praticien.",
          item: _vm.practicePlaceToDelete,
          visible: _vm.showDialogDeletePracticePlace,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showDialogDeletePracticePlace = $event
          },
          confirmed: function ($event) {
            return _vm.onDeletePracticePlaceConfirmed()
          },
        },
      }),
      _c("DeleteTableItemDialog", {
        attrs: {
          label:
            "Etes-vous sûr de vouloir sortir ce praticien du groupe Avec ?",
          item: _vm.practitioner,
          visible: _vm.showDialogDeletePractitionerGroup,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showDialogDeletePractitionerGroup = $event
          },
          confirmed: function ($event) {
            return _vm.onDeletePractitionerOfGroupConfirmed()
          },
        },
      }),
      _c("DeleteTableItemDialog", {
        attrs: {
          label: "Etes-vous sûr de vouloir blacklister ce praticien ?",
          item: _vm.practitioner,
          visible: _vm.showDialogBlacklistPractitioner,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showDialogBlacklistPractitioner = $event
          },
          confirmed: function ($event) {
            return _vm.onBlacklistPractitionerConfirmed()
          },
        },
      }),
      _c("DeleteTableItemDialog", {
        attrs: {
          label: "Etes-vous sûr de vouloir dé-blacklister ce praticien ?",
          item: _vm.practitioner,
          visible: _vm.showDialogUNBlacklistPractitioner,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showDialogUNBlacklistPractitioner = $event
          },
          confirmed: function ($event) {
            return _vm.onUNBlaclistPractitionerConfirmed()
          },
        },
      }),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }