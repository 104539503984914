<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="10">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Fiche praticien" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear
          indeterminate
          :active="loading || running"
        ></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la vue -->
              <div>Consulter les informations d'un praticien</div>

              <v-spacer></v-spacer>

              <!-- Bouton de modification -->
              <v-btn
                v-if="viewModifButton"
                icon
                color="primary"
                @click="clickOnModeEdition"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <!-- Bouton quit and save édition -->
              <v-row justify="end" no-gutters v-if="modeEdition">
                <v-btn
                  class="btn"
                  color="primary"
                  text
                  @click="clickOnCancelEdit"
                  :disabled="loading"
                >
                  Quitter l'édition
                </v-btn>

                <v-btn icon color="primary" :disabled="!canSave" @click="save">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>

          <!-- Recherche du praticien -->
          <v-card-text class="pb-1">
            <!-- Recherche par incrementalsearch -->
            <v-autocomplete
              :items="practitioners"
              v-model="selectedPractitioner"
              :search-input.sync="search"
              @input="selected"
              no-filter
              clearable
              no-data-text
              :loading="loading || running"
              label="Saisir le nom du praticien"
              :disabled="disabledSearchPractitioner"
            ></v-autocomplete>

            <!-- Recherche par idpp -->
            <v-row no-gutters>
              <v-text-field
                class="inputPrice"
                placeholder="Ou saisir un Identifiant National"
                dense
                v-model="idpp"
                clearable
                :disabled="disabledSearchPractitionerWithIdpp"
                v-on:keyup.enter="findWithIdpp"
              ></v-text-field>

              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                @click="findWithIdpp"
                :disabled="disabledSearchPractitionerWithIdpp"
              >
                Rechercher
              </v-btn>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <!-- Indique que le collaborateur n'a pas d'account -->
          <v-card-text
            v-if="viewMessagePractitionerHasNotAccount"
            class="pb-4"
          >
            <div class="text-h6 font-weight-regular error--text">
              Ce praticien n'a plus de compte Gsuite.
            </div>
          </v-card-text>

          <!-- Affichage des données du praticien -->
          <v-card-text v-if="practitioner">
            <v-row no-gutters>
              <v-col cols="9">
                <div>
                  <v-row no-gutters align="center" style="flex-wrap: nowrap">
                    <div
                      v-if="!isModeEdition && exerciseCivility"
                      class="text-h6 font-weight-bold"
                    >
                      {{ exerciseCivility.view }}
                    </div>
                    <!-- Civilité d'exercice -->
                    <v-autocomplete
                      v-else
                      class="flex-grow-0 flex-shrink-1"
                      :items="exerciseCivilities"
                      item-text="name"
                      v-model="exerciseCivility"
                      return-object
                    ></v-autocomplete>

                    <!-- Identité du praticien -->
                    <div class="text-h6 font-weight-bold mx-4">
                      {{ firstname }}
                      {{ name }}
                    </div>

                    <!-- genre du praticien -->
                    <div v-if="!isModeEdition" class="text-h6 font-weight-bold">
                      ({{ gender }})
                    </div>
                    <v-autocomplete
                      v-else
                      class="flex-grow-0 flex-shrink-1"
                      :items="genders"
                      v-model="gender"
                    ></v-autocomplete>
                  </v-row>

                  <!-- Affilliation du praticien Collaborateur ou Externe -->
                  <v-row no-gutters align="center">
                    <v-col cols="3">
                      <div class="capitalize font-weight-regular">
                        affiliation au groupe :
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div class="my-2">{{ type }}</div>
                    </v-col>
                  </v-row>

                  <!-- Numéro Rpps du praticien -->
                  <v-row no-gutters align="center">
                    <v-col cols="3">
                      <div class="my-2 capitalize font-weight-regular">
                        numéro RPPS :
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div class="my-2">{{ practitionerIdpp }}</div>
                    </v-col>
                  </v-row>

                  <!-- Texte de présentation affiché sur le site Avec -->
                  <v-row no-gutters align="center">
                    <div class="mt-2 capitalize font-weight-regular">
                      présentation sur le site Avec :
                    </div>
                  </v-row>
                  <v-row no-gutters align="center">
                    <div v-if="!isModeEdition" class="mt-2 mb-6">
                      {{ presentation }}
                    </div>
                    <v-textarea
                      v-else
                      filled
                      class="mb-6"
                      v-model="presentation"
                      placeholder="Présentation sur le site Avec"
                    >
                    </v-textarea>
                  </v-row>
                </div>
              </v-col>

              <!-- Boutons d'action sur le praticien -->
              <v-col cols="3" align="end" v-show="canEditRole">
                <!-- Pour un praticien groupe -- on peut le rendre externe -->
                <v-btn
                  v-if="practitionerIsCollaborater"
                  class="ma-2 px-4 btn"
                  outlined
                  color="primary"
                  @click="onPractitionerOutOfGroup"
                >
                  Sortir le praticien du groupe
                </v-btn>
                <!-- Pour un praticien groupe -- on peut le rendre externe -->
                <v-btn
                  v-if="practitionerIsBlacklisted"
                  class="ma-2 px-4 btn"
                  outlined
                  color="primary"
                  @click="onPractitionerUNBlaclist"
                >
                  Dé-blacklister le praticien
                </v-btn>
                <!-- Pour un praticien externe on peut le blaclister du site Avec -->
                <v-btn
                  v-if="practitionerIsExternal"
                  class="ma-2 px-4 btn"
                  outlined
                  color="primary"
                  @click="onPractitionerBlaclist"
                >
                  Blacklister le praticien
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-text>

          <!-- Partie données groupe du praticien -->
          <v-card-text v-if="practitioner && practitionerIsCollaborater">
            <v-row no-gutters align="center">
              <div class="capitalize text-h6 font-weight-regular">
                Les données groupe :
              </div>
            </v-row>

            <!-- Email du praticien -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="my-2 capitalize font-weight-regular">email :</div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ email }}</div>
              </v-col>
            </v-row>

            <!-- Etablissement du praticien -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="my-2 capitalize font-weight-regular">
                  établissement de rattachement :
                </div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ establishment }}</div>
              </v-col>
            </v-row>

            <!-- Activité du praticien -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="my-2 capitalize font-weight-regular">
                  activité :
                </div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ activity }}</div>
              </v-col>
            </v-row>

            <!-- Fonction primaire du praticien -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="my-2 capitalize font-weight-regular">
                  fonction :
                </div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ primaryFunction }}</div>
              </v-col>
            </v-row>

            <!-- Fonctions secondaires du praticien -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="my-2 capitalize font-weight-regular">
                  fonction(s) secondaire(s) :
                </div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ secondaryFunction }}</div>
              </v-col>
            </v-row>

            <!-- Titre d'employé du praticien -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="my-2 capitalize font-weight-regular">
                  titre employé :
                </div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ employeeTitle }}</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-text>

          <!-- Partie données de la profession -->
          <v-card-text v-if="practitioner">
            <!-- Le nom de la profession -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="capitalize font-weight-regular">Profession :</div>
              </v-col>
              <v-col cols="9">
                <div
                  v-if="!isModeEdition"
                  class="my-2 text-h6 font-weight-regular"
                >
                  {{ professionName }}
                </div>
                <v-autocomplete
                  v-else
                  class="flex-grow-0 flex-shrink-1"
                  placeholder="Sélectionner un nom de profession"
                  item-text="label"
                  :items="professionsNames"
                  v-model="selectedProfessionName"
                  no-data-text="aucun nom de profession"
                  @input="selectedProfessionNameAction"
                  clearable
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- Les/la profession médicales -->
            <v-row no-gutters align="center">
              <v-col cols="3">
                <div class="capitalize font-weight-regular">
                  Profession médicale :
                </div>
              </v-col>
              <v-col cols="9">
                <div class="my-2">{{ medicalProfession.label }}</div>
              </v-col>
            </v-row>

            <!-- Les/la spécialité médicales -->
            <v-row no-gutters align="center" class="mb-2">
              <v-col cols="3">
                <div class="capitalize font-weight-regular">
                  Spécialité médicale :
                </div>
              </v-col>
              <v-col cols="9">
                <div v-if="!isModeEdition" class="my-2 font-weight-regular">
                  {{ medicalSpeciality.name }}
                </div>
                <v-autocomplete
                  v-else
                  class="flex-grow-0 flex-shrink-1"
                  placeholder=""
                  item-text="name"
                  :items="medicalSpecialities"
                  v-model="selectedMedicalSpeciality"
                  no-data-text="aucune spécialité médicale"
                  :disabled="disableComboMedicalSpeciality"
                  :rules="[rules.validateMedicalSpeciality]"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-text>

          <!-- Partie donnée des lieux d'exercice du praticien -->
          <v-card-text v-if="practitioner">
            <!-- Afficher une combo pour l'ajout avec un bouton + -->
            <v-row v-if="isModeEdition" no-gutters align="center">
              <v-col cols="3">
                <div class="capitalize font-weight-regular">
                  Ajouter un lieu d'exercice :
                </div>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  class="flex-grow-0 flex-shrink-1"
                  placeholder="Sélectionner un lieux d'exercice"
                  item-text="name"
                  item-value="name"
                  :items="availablesPracticesPlaces"
                  v-model="selectedPracticePlace"
                  no-data-text="aucuns lieux d'exercice"
                  return-object
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="primary"
                  icon
                  @click="addPracticePlace()"
                  :disabled="!canAddPracticePlace"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!-- La liste des lieux d'exercices -->
            <v-row no-gutters class="mt-4">
              <v-col cols="3">
                <v-row no-gutters align="center">
                  <div class="capitalize text-h6 font-weight-regular">
                    Les lieux d'exercice :
                  </div>
                </v-row>
              </v-col>
              <v-col cols="9" class="mt-6">
                <div v-for="(pp, i) in practicesPlaces" :key="i">
                  <!-- Le nom du lieux d'exercie -->
                  <v-row no-gutters align="center" class="mb-1">
                    <v-col cols="2">
                      <div class="capitalize font-weight-regular">Nom :</div>
                    </v-col>
                    <v-col cols="9">
                      <span class="text-subtitle-1 font-weight-bold">{{
                        pp.name
                      }}</span
                      >&nbsp;<span class="font-weight-thin font-italic"
                        >({{ pp.practicePlaceId }})</span
                      >
                    </v-col>
                    <v-col cols="1" v-if="viewDeletePracticePlace">
                      <v-btn
                        icon
                        color="primary"
                        @click="deletePracticePlace(pp)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- L'adresse du lieux d'exercice -->
                  <v-row no-gutters align="center">
                    <v-col cols="2" align-self="start">
                      <div class="capitalize font-weight-regular">
                        Adresse :
                      </div>
                    </v-col>
                    <v-col cols="10">
                      <div class="font-weight-regular">
                        {{ addressOfPracticePlace(pp).address }}
                      </div>
                      <div class="font-weight-regular">
                        {{ addressOfPracticePlace(pp).postalCode }}
                        {{ addressOfPracticePlace(pp).city }}
                      </div>
                    </v-col>
                  </v-row>
                  <!-- Le téléphone du lieux d'exercice -->
                  <v-row no-gutters align="center">
                    <v-col cols="2" align-self="start">
                      <div class="capitalize font-weight-regular">
                        Téléphone :
                      </div>
                    </v-col>
                    <v-col cols="10">
                      <div class="font-weight-regular">{{ pp.phone }}</div>
                    </v-col>
                  </v-row>

                  <!-- L'url de la page praticien selon son lieu d'exercice -->
                  <v-row
                    no-gutters
                    align="center"
                    class="my-4"
                    v-if="viewUrlWebSite(pp)"
                  >
                    <v-col cols="2" align-self="center">
                      <div class="capitalize font-weight-regular">
                        Lien du site Avec :
                      </div>
                    </v-col>
                    <v-col cols="10">
                      <v-btn
                        class="btn pl-0"
                        text
                        color="primary"
                        :loading="running"
                        link
                        :href="urlSlugPractitionner(pp)"
                        target="_blank"
                      >
                        {{ slug(pp) }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!-- Les agendas YESDOC -->
                  <v-row
                    v-if="viewYesdocAgendasForPracticePlace(pp)"
                    no-gutters
                    align="center"
                    class="my-4"
                  >
                    <v-col cols="2" align-self="start">
                      <div class="capitalize font-weight-regular">
                        agendas YESDOC :
                      </div>
                    </v-col>
                    <v-col cols="10">
                      <div
                        v-for="(agenda, i) in yesdocAgendaOfPracticePlace(pp)"
                        :key="'A' + i"
                        class="font-weight-regular"
                      >
                        <v-row no-gutters>
                          <v-col cols="11">
                            {{ agenda }}
                          </v-col>
                          <v-col cols="1" v-if="isModeEdition">
                            <v-btn
                              icon
                              color="primary"
                              @click="deleteYesdocAgenda(pp, agenda)"
                            >
                              <v-icon>mdi-window-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>

                      <!-- L'ajout d'un agenda yesdoc -->
                      <v-row v-if="isModeEdition" no-gutters align="center">
                        <v-col cols="11">
                          <v-text-field
                            placeholder="L'uuid de l'agenda yesdoc"
                            dense
                            v-model="yesdocAgendaToAdd"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            color="primary"
                            icon
                            @click="addYesdocAgenda(pp)"
                            :disabled="!canAddYesdocAgenda"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <div v-if="pp.practitionerOnPracticePlace">
                    <!-- Agrément Sécurité sociale -->
                    <v-row no-gutters align="center" class="my-4">
                      <v-col cols="2" align-self="center">
                        <div class="capitalize font-weight-regular">
                          Agrément :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div class="font-weight-regular">
                          {{
                            practitionerOnPracticePlaceOfPracticePlace(pp)
                              .agreement
                          }}
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Montant du dépassement d'honoraire -->
                    <v-row no-gutters align="center" class="my-4">
                      <v-col cols="2" align-self="center">
                        <div class="capitalize font-weight-regular">
                          Dépassement d'honoraire :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div class="font-weight-regular">
                          {{
                            practitionerOnPracticePlaceOfPracticePlace(pp)
                              .excessFee
                          }}
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Les expertises du praticien -->
                    <v-row no-gutters align="center" class="my-4">
                      <v-col cols="2" align-self="start">
                        <div class="capitalize font-weight-regular">
                          expertises :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div
                          v-for="(
                            expertise, i
                          ) in practitionerOnPracticePlaceOfPracticePlace(pp)
                            .expertises"
                          :key="'C' + i"
                          class="font-weight-regular"
                        >
                          {{ expertise }}
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Téléphone du secrétariat -->
                    <v-row no-gutters align="center" class="my-4">
                      <v-col cols="2" align-self="center">
                        <div class="capitalize font-weight-regular">
                          téléphone secrétariat :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div class="font-weight-regular">
                          {{
                            practitionerOnPracticePlaceOfPracticePlace(pp)
                              .secretariat.phone
                          }}
                        </div>
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="practicesPlaces.length > 1"
                      class="my-4"
                    ></v-divider>

                    <!-- Les horaires d'ouverture du secrétariat -->
                    <!-- <v-row no-gutters align="center" class="my-4">
                      <v-col cols="2" align-self="start">
                        <div class="capitalize font-weight-regular">horaires secrétariat : </div>
                      </v-col>
                      <v-col cols="10">
                        <div
                        v-for="(schedule, i) in practitionerOnPracticePlaceOfPracticePlace(pp).secretariat.schedules" :key="'D' + i"
                        class="font-weight-regular">
                          {{ schedule }}
                        </div>
                      </v-col>
                    </v-row> -->
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <DeleteTableItemDialog
      label="Le lieu d'exercice se trouve au sein de l'établissement de rattachement du praticien."
      :item="practicePlaceToDelete"
      :visible.sync="showDialogDeletePracticePlace"
      @confirmed="onDeletePracticePlaceConfirmed()"
    ></DeleteTableItemDialog>

    <DeleteTableItemDialog
      label="Etes-vous sûr de vouloir sortir ce praticien du groupe Avec ?"
      :item="practitioner"
      :visible.sync="showDialogDeletePractitionerGroup"
      @confirmed="onDeletePractitionerOfGroupConfirmed()"
    ></DeleteTableItemDialog>

    <DeleteTableItemDialog
      label="Etes-vous sûr de vouloir blacklister ce praticien ?"
      :item="practitioner"
      :visible.sync="showDialogBlacklistPractitioner"
      @confirmed="onBlacklistPractitionerConfirmed()"
    ></DeleteTableItemDialog>

    <DeleteTableItemDialog
      label="Etes-vous sûr de vouloir dé-blacklister ce praticien ?"
      :item="practitioner"
      :visible.sync="showDialogUNBlacklistPractitioner"
      @confirmed="onUNBlaclistPractitionerConfirmed()"
    ></DeleteTableItemDialog>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DeleteTableItemDialog from "@/components/ui/DeleteTableItemDialog.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { PractitionnerService } from "@/service/practitioners/practitioner_service.js";
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { CollaboratorsService } from "@/service/user/collaborators_service.js";
import { CollaboratorPractitionerService } from "@/service/practitioners/collaborator_practitioner_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";
import { areTheSame } from "@/tools/string_tool.js";

import * as logger from "@/tools/logger.js";
import * as exceptions from "@/service/exception_to_message.js";

import {
  convertPractitionerGender,
  convertPractitionerType,
  exerciseCivilitiesEnum,
  reversePractitionerGender,
} from "@/views/practitioners/practitionerEnums.js";

export default {
  name: "FilePractitionner",

  components: {
    Workflow,
    TitleAndReturnComponent,
    DeleteTableItemDialog,
    AlertNotSavedModifsComponent,
  },

  mixins: [WorkflowMixin, AlertNotSavedModifsMixin, SnackBarMixin],

  data() {
    return {
      /** le service des praticien */
      servicePractitioner: null,
      serviceMedicalSpecialties: null,
      serviceCollaborators: null,
      serviceFunction: null,
      serviceEstablishments: null,
      serviceActivities: null,
      serviceCollaboratorPractitioner: null,

      /** Les praticiens retournés par la recherche incrémentale */
      practitioners: [],
      /** Le praticein sélectionné par la recherche */
      selectedPractitioner: null,
      /** la date de la dernière recherche (pour éviter les résultats croisés) */
      lastSearchTimestamp: Date.now(),

      /** La source praticien*/
      practitionerSource: null,
      /** le praticien sélectionné */
      practitioner: null,
      /** les données dguser du praticien  */
      collaboratorPractitionner: null,
      /** variable pour la saisie de recherche du praticien */
      search: "",

      /** variable pour la recherche par idpp */
      idpp: null,

      /** variable pour l'affichage de chargement des données */
      loading: false,
      running: false,

      /** Le datas du praticien */
      practitionerIdpp: null,
      exerciseCivility: null,
      firstname: null,
      name: null,
      gender: null,
      type: null,
      presentation: null,

      email: null,
      establishment: null,
      activity: null,
      primaryFunction: null,
      secondaryFunction: null,
      employeeTitle: null,

      professionName: null,
      medicalProfession: {
        codeMos: "",
        label: "",
        slug: "",
      },
      medicalSpeciality: {
        code: "",
        name: "",
        commercialName: "",
      },

      /** La liste des nom de professions */
      professionsNames: [],
      /** La liste des spécialités médicales disponibles pour le nom de profession*/
      medicalSpecialities: [],
      /** La liste des lieux d'exercices du praticien */
      practicesPlaces: [],

      /** La liste des lieux d'exercice de la base */
      practicePlacesList: [],
      /** La liste des spécialité médicales */
      medicalSpecialitiesList: [],

      /** Le nom de la profession sélectionné */
      selectedProfessionName: null,
      /** La spécialité médicale sélectionnée */
      selectedMedicalSpeciality: null,
      /** Le lieu d'exercice sélectionné */
      selectedPracticePlace: null,
      /** L'agenda yesdoc à ajouter */
      yesdocAgendaToAdd: null,

      /** Contient toutes les fonctions du groupe */
      allFunctions: [],

      exerciseCivilities: [],
      genders: ["Homme", "Femme"],

      /** Base de l'url du site Avec */
      baseUrlAvec: null,
      /** Le texte à afficher sur le bouton des slugs */
      slugText: null,
      /** L'url de la fiche praticien */
      urlSlugPractitioner: null,

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: false,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      // Le lieux d'exercice à supprimer
      practicePlaceToDelete: null,
      // Affiche la dialog pour la confirmation de suppression du lieu d'exercice
      showDialogDeletePracticePlace: null,

      // La liste des agendas yesdoc à manipuler
      yesDocAgendas: [
        {
          practicePlaceId: null,
          agendas: [],
        },
      ],

      // Affiche la dialog pour la confirmation de sortie du praticien du groupe
      showDialogDeletePractitionerGroup: false,
      // Affiche la dialog pour la confirmation de blacklistage du praticien
      showDialogBlacklistPractitioner: false,
      // Affiche la dialog pour la confirmation de dé-blacklistage du praticien
      showDialogUNBlacklistPractitioner: false,

      /** Les règles des différents champs */
      rules: {
        validateMedicalSpeciality: () =>
          this.checkMedicalSpeciality() ||
          "veuillez sélectionner une spécialité médicale",
      },

      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditPracticioner],
    };
  },

  methods: {
    /** Chargement des datas nécessaires à la vue */
    async load() {
      try {
        this.loading = true;

        // Récupération de l'ensembles des spécialités médicales
        this.medicalSpecialitiesList =
          await this.serviceMedicalSpecialties.getAll();
        // Récupération de l'ensemble des fonctions.
        this.allFunctions = await this.serviceFunction.getFunctions();
        // Récupération de l'ensemble des noms de professions
        this.professionsNames =
          await this.servicePractitioner.getAllProfessioNames();
        // Récupération de l'ensemble des lieux d'exercie
        this.practicePlacesList =
          await this.servicePractitioner.getAllPracticePlace();
        // Récupération de l'ensemble des civilités
        this.exerciseCivilities = exerciseCivilitiesEnum();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Lance la recherche auprès de l'api et transforme la réponse en liste de praticiens */
    findPractitioner(name) {
      if (!name || name == "") {
        // le teste de recherche est vide, on reset
        this.selectedPractitioner = null;
        this.practitioners = [];
        return;
      }

      /** Obligation de saisie à 5 caractère */
      if (name.length < 5) {
        this.practitioners = [];
        return;
      }

      let now = Date.now();
      this.lastSearchTimestamp = now;

      this.loading = true;

      this.servicePractitioner
        .searchPractitionerByName(name, now)
        .then((results) => {
          // On vérifie que l'on ne garde pas les réponse précédentes
          if (results.ts < this.lastSearchTimestamp) {
            return;
          }

          let practitioners = [];
          results.practitioners.forEach((practitioner) => {
            let city = practitioner.practicePlaces[0].address.city || "";
            let typePractitioner = convertPractitionerType(practitioner.type);
            practitioners.push({
              text: `${practitioner.name} ${practitioner.firstname} (${typePractitioner}) - ${practitioner.professionName} - ${city}`,
              value: practitioner,
            });
          });
          // Tri des praticiens trouvés
          practitioners.sort(function (a, b) {
            return a.text.localeCompare(b.text);
          });

          this.practitioners = practitioners;
        })
        .catch((err) => {
          console.error("problème lors de la recherche par email:" + err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /** Sélectionne et lance la recherche du praticien */
    selected(value) {
      try {
        if (!value) {
          this.selectedPractitioner = null;
          return;
        }

        // logger.debug("value of selected", value);
        // let city = value.practicePlaces[0].address.city || "";
        // let typePractitioner = convertPractitionerType(value.type);

        //           this.selectedPractitioner.text =  `${value.name} ${value.firstname} (${typePractitioner}) - ${value.professionName} - ${city}`;
        // logger.debug("value of selected Pact", this.selectedPractitioner);

        this.running = true;
        // L'objet praticien étant incomplet, on lance une recherche avec son l'idpp
        this.findPractitionerWithIdpp(value.idPP);
      } catch (error) {
        logger.error("Erreur chargement datas user :", error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    /** Lance une recherche de praticien par idpp saisi */
    async findWithIdpp() {
      this.findPractitionerWithIdpp(this.idpp);
    },

    /** Lance la recherche du praticien par l'idPP donné */
    async findPractitionerWithIdpp(idpp) {
      let success = true;

      this.loading = true;
      this.resetDatas();

      try {
        /** Récupère le praticien par son idpp et le recopie dans le source*/
        this.practitioner =
          await this.servicePractitioner.searchPractitionerByIdpp(idpp);
        this.practitionerSource = JSON.parse(JSON.stringify(this.practitioner));
      } catch (error) {
        success = false;
        logger.error("Erreur pendant le chargement des datas : ", error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }

      if (success) {
        await this.findCollaboratorByIdpp(idpp);
        await this.initDatasPractitioner();
      }
    },

    /** Lance la recherche du dguser */
    async findCollaboratorByIdpp(idpp) {
      try {
        this.loading = true;

        // Si le praticien est du groupe on charge ses datas de groupe
        if (this.practitionerIsCollaborater) {
          // Obtient l'id user à partir de l'idpp
          const collaborator =
            await this.servicePractitioner.searchPractitionerCollaboratorByIdpp(
              idpp
            );
          // Récupère les datas du praticien dans la base user
          this.collaboratorPractitionner =
            await this.serviceCollaborators.getByUuid(
              collaborator.collaboratorId
            );
        }
      } catch (error) {
        logger.error("Erreur pendant le chargement des datas : ", error);
        this.addErrorToSnackbar(
          "Erreur de chargement des données user : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async initDatasPractitioner() {
      logger.debug(" praticien ", this.practitioner);

      this.practitionerIdpp = this.practitioner.idPP;
      this.exerciseCivility = this.exerciseCivilities.find(
        (e) => e.value == this.practitioner.exerciseCivility
      );
      this.firstname = this.practitioner.firstname || "";
      this.name = this.practitioner.name || "";
      this.gender = convertPractitionerGender(this.practitioner.gender);
      this.type = convertPractitionerType(this.practitioner.type);
      this.presentation = this.practitioner.presentation || "";

      // Initialisation des profession
      this.professionName = this.practitioner.professionName || "";
      if (this.professionName || this.professionName != "") {
        let found = this.professionsNames.find(
          (p) => p.label == this.professionName
        );
        this.selectedProfessionName = found;
      }

      if (this.practitioner.medicalProfession) {
        this.medicalProfession = this.practitioner.medicalProfession;
      }

      // Initialisation des spécialités médicales
      if (this.practitioner.medicalSpecialty) {
        this.medicalSpeciality = this.practitioner.medicalSpecialty;

        if (this.selectedProfessionName) {
          if (this.selectedProfessionName.medicalSpecialties) {
            this.medicalSpecialities =
              this.selectedProfessionName.medicalSpecialties;
            this.selectedMedicalSpeciality = this.medicalSpeciality;
          }
        }
      }

      // Initialisation des lieux d'exercices
      this.practicesPlaces = this.practitioner.practicePlaces;
      // Parcours les lieu d'exercices pour en extraire les agenda yesdoc
      for (let pp of this.practicesPlaces) {
        if (pp.agendaIds) {
          let entity = {};
          entity.practicePlaceId = pp.practicePlaceId;
          entity.agendas = JSON.parse(JSON.stringify(pp.agendaIds));

          this.yesDocAgendas.push(entity);
        }
      }

      // Récupération des datas lorsque le praticien est collaborateur
      if (this.practitionerIsCollaborater && this.collaboratorPractitionner) {
        if (this.collaboratorPractitionner.function) {
          // Recherche de la fonction primaire du praticien
          let foundPrimary = this.allFunctions.find(
            (f) => f.id == this.collaboratorPractitionner.function
          );
          this.primaryFunction = foundPrimary.name;
        }

        // Parcours l'ensemble des fonctions secondaires
        for (
          let i = 0;
          i < this.collaboratorPractitionner.secondaryFunctions.length;
          i++
        ) {
          let secFunc = this.collaboratorPractitionner.secondaryFunctions[i];
          // Recherche la fonction dans sa liste
          let foundSecondary = this.allFunctions.find((f) => f.id == secFunc);
          // Si est i != 0 on ne met pas de virgule
          if (i == 0) {
            this.secondaryFunction = foundSecondary.name;
          } else {
            this.secondaryFunction += `, ${foundSecondary.name}`;
          }
        }

        // Récupération de l'email du praticien
        if (this.collaboratorPractitionner.account) {
          this.email = this.collaboratorPractitionner.account.email;
        }
        // Récupération du titre d'employé
        if (this.collaboratorPractitionner.employeeTitle) {
          this.employeeTitle = this.collaboratorPractitionner.employeeTitle;
        }

        // Récupération de l'établissement
        if (this.collaboratorPractitionner.establishment) {
          let establishment = await this.serviceEstablishments.getById(
            this.collaboratorPractitionner.establishment
          );
          this.establishment = establishment.digitalName;
        }

        // Récupération de l'activité
        if (this.collaboratorPractitionner.activity) {
          let activity = await this.serviceActivities.getActivityById(
            this.collaboratorPractitionner.activity
          );
          this.activity = activity.name;
        }
      }
    },

    /** Réinitialise toutes les données possibles pour un praticien */
    resetDatas() {
      this.practitionerSource = null;
      this.practitioner = null;
      this.collaboratorPractitionner = null;

      this.practitionerIdpp = null;
      this.exerciseCivility = null;
      this.firstname = null;
      this.name = null;
      this.gender = null;
      this.type = null;
      this.presentation = null;

      this.email = null;
      this.establishment = null;
      this.activity = null;
      this.primaryFunction = null;
      this.secondaryFunction = null;
      this.employeeTitle = null;

      this.professionName = null;
      this.medicalProfession = {
        codeMos: "",
        label: "",
        slug: "",
      };
      this.medicalSpeciality = {
        code: "",
        name: "",
        commercialName: "",
      };
      this.medicalSpecialities = [];

      this.selectedProfessionName = null;
      this.selectedMedicalSpeciality = null;
      this.selectedPracticePlace = null;
      this.yesdocAgendaToAdd = null;
      this.yesDocAgendas = [];

      this.slugText = null;
      this.urlSlugPractitioner = null;

      this.practicePlaceToDelete = null;
    },

    /** Sauvegarde des datas praticien */
    async save() {
      let success = true;

      try {
        this.running = true;

        let entity = {};
        entity.exerciseCivility = this.exerciseCivility.value;
        entity.gender = reversePractitionerGender(this.gender);
        entity.presentation = this.presentation;
        entity.medicalProfessionId = this.medicalProfession.codeMos;

        // Recherche de la spécialité médicale
        entity.medicalSpecialtyId = null;
        if (this.selectedMedicalSpeciality) {
          let found = this.medicalSpecialitiesList.find(
            (m) => m.nomenclature == this.selectedMedicalSpeciality.code
          );
          entity.medicalSpecialtyId = found.id;
        }
        // Sauvegarde de l'identité et de la profession
        await this.servicePractitioner.saveIdentity(
          this.practitionerSource.idPP,
          entity
        );
        // Sauvegarde des lieux d'exercices
        await this.savePracticePlace();

        this.findPractitionerWithIdpp(this.practitionerSource.idPP);
      } catch (error) {
        success = false;
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement des données du praticien : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }

      if (success) {
        this.disableAlertQuit();
        this.modeEdition = false;
      }
    },

    /** Sauvegarde des lieu d'exercices */
    async savePracticePlace() {
      if (this.practicePlacesHasChanged || this.yesdocAgendasHasCHanged) {
        // Parcours chaque lieu visuel
        for (let pp of this.practicesPlaces) {
          let found = null;
          // Recherche du lieu dans la source
          if (this.practitionerSource.practicePlaces) {
            found = this.practitionerSource.practicePlaces.find(
              (p) => p.practicePlaceId == pp.practicePlaceId
            );
          }

          // Non trouvé --> nouveau
          if (!found) {
            // Création du lieu d'exercice
            await this.servicePractitioner.addPracticePlace(
              this.practitionerSource.idPP,
              pp.practicePlaceId
            );

            // Recherche de l'angenda yesdoc
            let foundAgendas = this.yesDocAgendas.find(
              (y) => y.practicePlaceId == pp.practicePlaceId
            );
            // As-t-on des agendas
            if (foundAgendas) {
              for (let agenda of foundAgendas.agendas) {
                let entity = {};
                entity.practicePlaceId = pp.practicePlaceId;
                entity.agendaId = agenda;

                // Sauvegarde de chaque agenda
                await this.servicePractitioner.addAgendaToPracticePlace(
                  this.practitionerSource.idPP,
                  entity
                );
              }
            }
          } else {
            // Trouvé --> on compare les agendas s'il y en as
            let agendasToSave = [];
            // Recherche de l'agenda yesdoc dans le visuel
            let foundAgenda = this.yesDocAgendas.find(
              (y) => y.practicePlaceId == pp.practicePlaceId
            );

            // Si l'agendas original n'existe pas on ajoute tout
            if (!found.agendaIds) {
              agendasToSave.push(...foundAgenda.agendas);
            } else {
              // Pour chaque agenda visuel -- si trouvé et non trouvé dans la source --> il est nouveau
              for (let agenda of foundAgenda.agendas) {
                // Recherche de l'agenda dans la source
                let foundAgendaSource = found.agendaIds.find(
                  (a) => a == agenda
                );

                if (!foundAgendaSource) {
                  agendasToSave.push(agenda);
                }
              }

              // Pour chaque agenda source, s'il est non trouvé --> il est supprimé
              for (let agenda of found.agendaIds) {
                // Recherche de l'agenda dans le visuel
                let agendaVisuel = foundAgenda.agendas.find((a) => a == agenda);

                // Si on ne trouve pas, on supprime l'agenda de la base
                if (!agendaVisuel) {
                  await this.servicePractitioner.deleteYesdocAgenda(
                    this.practitionerSource.idPP,
                    agenda
                  );
                }
              }
            }
            // Parcours la liste des agenda à ajouter
            for (let yesdocAgenda of agendasToSave) {
              let entity = {};
              entity.practicePlaceId = pp.practicePlaceId;
              entity.agendaId = yesdocAgenda;

              // Sauvegarde de chaque agenda
              await this.servicePractitioner.addAgendaToPracticePlace(
                this.practitionerSource.idPP,
                entity
              );
            }
          }
        }

        // Parcours des lieux d'exercie de la source pour les suppressions
        for (let ppSource of this.practitionerSource.practicePlaces) {
          // Recherche du lieu dans le visuel
          let found = this.practicesPlaces.find(
            (p) => p.practicePlaceId == ppSource.practicePlaceId
          );

          if (!found) {
            // Sppression du lieu d'exercice (cascadde prévue par l'api --> les agnedas se supprimeront)
            await this.servicePractitioner.deletePracticePlace(
              this.practitionerSource.idPP,
              ppSource.practicePlaceId
            );
          }
        }
      }
    },

    // Affiche la demande de confirmation de suppression du praticien du groupe
    onPractitionerOutOfGroup() {
      this.showDialogDeletePractitionerGroup = true;
    },
    // Confirmation de suppression du praticien du groupe
    async onDeletePractitionerOfGroupConfirmed() {
      try {
        this.running = true;

        await this.serviceCollaboratorPractitioner.deletePractitionerCollaborator(
          this.collaboratorPractitionner.uuid
        );
        this.resetDatas();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "suppression du praticien collaborateur : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    // Affiche la demande de confirmation de blacklistage
    onPractitionerBlaclist() {
      this.showDialogBlacklistPractitioner = true;
    },
    // Confirmation de suppression du praticien du groupe
    async onBlacklistPractitionerConfirmed() {
      try {
        this.running = true;

        await this.servicePractitioner.blacklistPractitioner(
          this.practitionerSource.idPP
        );
        this.resetDatas();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "blacklistage du praticien : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    /** Affiche la demande de confirmationde déblacklistage */
    onPractitionerUNBlaclist() {
      this.showDialogUNBlacklistPractitioner = true;
    },
    /** Confirmation de déblacklistage du praticien */
    async onUNBlaclistPractitionerConfirmed() {
      try {
        this.running = true;

        await this.servicePractitioner.unBlacklistPractitioner(
          this.practitionerSource.idPP
        );
        this.resetDatas();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "dé-blacklistage du praticien : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    /** Ajoute un lieux d'exercice à la liste de ceux du praticien */
    addPracticePlace() {
      if (this.selectedPracticePlace) {
        // Recherche si présence du pp dans la source
        let foundPP = this.practitionerSource.practicePlaces.find(
          (p) => p.practicePlaceId == this.selectedPracticePlace.practicePlaceId
        );

        if (foundPP) {
          // Ajout du lieu d'exercice depuis la source
          this.practicesPlaces.push(foundPP);

          // Ajout des agendas de la source.
          let entity = {};
          entity.practicePlaceId = foundPP.practicePlaceId;
          entity.agendas = JSON.parse(JSON.stringify(foundPP.agendaIds));

          this.yesDocAgendas.push(entity);
        } else {
          // Pas de présence dans la source on ajoute la sélection
          this.practicesPlaces.push(this.selectedPracticePlace);
        }

        this.selectedPracticePlace = null;
      }
    },

    /** Supprime un lieux d'exercice de la liste */
    deletePracticePlace(practicePlace) {
      this.practicePlaceToDelete = practicePlace;
      // La restriction d'association à l'estab n'est que pour les collaborateur
      if (this.collaboratorPractitionner) {
        // Recherche du lieux d'exercice dans la liste source
        let found = this.practicePlacesList.find(
          (p) => p.practicePlaceId == practicePlace.practicePlaceId
        );

        // Si le lieu d'exercice est relié à l'établissement du praticien on préviens
        if (
          this.collaboratorPractitionner.establishment == found.establishmentId
        ) {
          this.showDialogDeletePracticePlace = true;
        } else {
          this.onDeletePracticePlaceConfirmed();
        }
      } else {
        this.onDeletePracticePlaceConfirmed();
      }
    },

    /** Confirmation de la suppression du lieu d'exercice */
    onDeletePracticePlaceConfirmed() {
      let ppId = this.practicePlaceToDelete.practicePlaceId;

      // Suppression du lieu dans la liste
      this.practicesPlaces.splice(
        this.practicesPlaces.findIndex((p) => p.practicePlaceId == ppId),
        1
      );
      this.practicePlaceToDelete = null;

      // Suppression de l'ensemble des agendas yesdoc si le lieu d'exercice en possède
      let found = this.yesDocAgendas.find((y) => y.practicePlaceId == ppId);
      if (found) {
        this.yesDocAgendas.splice(
          this.yesDocAgendas.findIndex((y) => y.practicePlaceId == ppId),
          1
        );
      }
    },

    /** Ajoute un agenda yesdoc à la lite du praticien */
    addYesdocAgenda(practicePlace) {
      if (practicePlace) {
        // Recherche l'objet agenda associé au lieu d'exercice
        let found = this.yesDocAgendas.find(
          (y) => y.practicePlaceId == practicePlace.practicePlaceId
        );

        // Si on ne le trouve pas, on crée un objet agenda
        if (!found) {
          found = {};
          found.practicePlaceId = practicePlace.practicePlaceId;
          found.agendas = [];
          // Ajout de l'objet agenda créé dans le tableau des agendas
          this.yesDocAgendas.push(found);
        }

        // Ajout de l'agenda dans la liste des agendas du lieu d'exercice
        found.agendas.push(this.yesdocAgendaToAdd);

        // Reset de la saisie
        this.yesdocAgendaToAdd = null;
      }
    },

    /** Suppression d'un agenda dans un lieu d'exercice */
    deleteYesdocAgenda(practicePlace, agenda) {
      if (practicePlace && agenda) {
        // Recherche l'objet agenda associé au lieu d'exercice
        let found = this.yesDocAgendas.find(
          (y) => y.practicePlaceId == practicePlace.practicePlaceId
        );

        // Supprime l'agenda dans le lieu d'exercice
        found.agendas.splice(
          found.agendas.findIndex((f) => f == agenda),
          1
        );
      }
    },

    /** Retourne l'url de la page sur le site Avec pour ce lieu d'exercice */
    urlSlugPractitionner(practicePlace) {
      let url = "";

      if (practicePlace.slugs) {
        let slug = practicePlace.slugs;
        url = `${this.baseUrlAvec}/praticien/${slug.slugProfession}/${slug.slugCityName}/${slug.slugFullName}`;
      }

      return url;
    },

    /** Retourne le texte calculé du bouton de consultation de la fiche praticien */
    slug(practicePlace) {
      let slug = "";

      if (practicePlace) {
        if (this.exerciseCivility.value) {
          slug = `Consulter la fiche du ${this.exerciseCivility.name} ${this.firstname} ${this.name}`;
        } else {
          slug = `Consulter la fiche de ${this.firstname} ${this.name}`;
        }
      }
      return slug;
    },

    clickOnModeEdition() {
      this.modeEdition = true;
    },

    /** click sur le bouton de quit du mode édition */
    clickOnCancelEdit() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function (value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.findPractitionerWithIdpp(this.practitioner.idPP);
          }
        };
      } else {
        this.modeEdition = false;
      }
    },

    // Fournit l'adresse du practice place en param
    addressOfPracticePlace(practicePlace) {
      let address = {
        address: "",
        postalCode: "",
        city: "",
      };
      // Si le lieux d'exercice n'est pas null et l'adresse qu'il contient aussi
      // on fabrique notre objet adresse
      if (practicePlace) {
        if (practicePlace.address) {
          address.address = practicePlace.address.address;
          address.postalCode = practicePlace.address.postalCode;
          address.city = practicePlace.address.city;
        }
      }
      return address;
    },

    // Fournit la liste des agenda Yesdoc du lieux d'exercice
    yesdocAgendaOfPracticePlace(practicePlace) {
      let result = [];

      if (practicePlace) {
        // Recherche l'objet agenda associé au lieu d'exercice
        let found = this.yesDocAgendas.find(
          (y) => y.practicePlaceId == practicePlace.practicePlaceId
        );

        // Si trouvé on copie les agendas
        if (found) {
          result.push(...found.agendas);
        }
      }

      return result;
    },

    /** Retourne la visibilité des agendas yesdoc pour un lieux d'exercice */
    viewYesdocAgendasForPracticePlace(practicePlace) {
      // Si on est pas en mode édition et qu'il n'y a pas d'agenda --> on cache
      if (
        !this.modeEdition &&
        this.yesdocAgendaOfPracticePlace(practicePlace).length <= 0
      ) {
        return false;
      }
      return true;
    },

    /** Retourne la visibilité du lien vers la page du site Avec pour le praticien et lieu d'exercice */
    viewUrlWebSite(practicePlace) {
      if (practicePlace.slugs) {
        return true;
      }
      return false;
    },

    // Fournit les données du praticien sur son lieu d'exercice
    practitionerOnPracticePlaceOfPracticePlace(practicePlace) {
      let practitionerOnPracticePlace = {
        agreement: "",
        excessFee: "",
        expertises: [],
        secretariat: {
          phone: "",
          schedules: [],
        },
      };
      // Si le lieux d'exercice de n'est pas null et que les datas de ce lieux existes
      // on fabrique les datas du lieux
      if (practicePlace) {
        if (practicePlace.practitionerOnPracticePlace) {
          practitionerOnPracticePlace.agreement =
            practicePlace.practitionerOnPracticePlace.agreement;
          practitionerOnPracticePlace.excessFee =
            practicePlace.practitionerOnPracticePlace.excessFee;
          practitionerOnPracticePlace.expertises =
            practicePlace.practitionerOnPracticePlace.expertises;

          if (practicePlace.practitionerOnPracticePlace.secretariat) {
            practitionerOnPracticePlace.secretariat.phone =
              practicePlace.practitionerOnPracticePlace.secretariat.phone;
            practitionerOnPracticePlace.secretariat.schedules =
              practicePlace.practitionerOnPracticePlace.secretariat.schedules;
          }
        }
      }
      return practitionerOnPracticePlace;
    },

    // Action de sélection d'une profession médicale
    selectedProfessionNameAction() {
      // Si un nom de profession à été choisi, on redéfini la profession médicale
      if (this.selectedProfessionName) {
        // On récupère la profession médicale dans l'objet
        this.medicalProfession = this.selectedProfessionName.medicalProfession;
        this.medicalSpecialities =
          this.selectedProfessionName.medicalSpecialties;

        this.selectedMedicalSpeciality = null;

        if (this.medicalSpecialities.length > 0) {
          // Dans le cas où on as qu'une seule spécialité médical, on la sélectionne d'office.
          if (this.medicalSpecialities.length == 1) {
            this.selectedMedicalSpeciality = this.medicalSpecialities[0];
          } else {
            // Dans le cas du retour à l'original
            this.selectedMedicalSpeciality = this.medicalSpecialities.find(
              (m) => m.code == this.medicalSpeciality.codeMos
            );
          }
        }
      }
    },

    /** Règle de vérification de saisie des spécialités médicale */
    checkMedicalSpeciality() {
      let result = true;

      if (this.selectedProfessionName) {
        if (
          this.selectedProfessionName.medicalSpecialties.length > 1 &&
          !this.selectedMedicalSpeciality
        ) {
          result = false;
        }
      }
      return result;
    },
  },

  computed: {
    /** Selon le contexte, désactive la recherche de praticien autoincrémentale */
    disabledSearchPractitioner() {
      if (!this.idpp || this.idpp == "") {
        return false;
      }
      return true;
    },

    /** Selon le contexte, désactive la recherche de praticien par idpp */
    disabledSearchPractitionerWithIdpp() {
      if (!this.search || this.search == "") {
        return false;
      }
      return true;
    },

    /** Désactive la combo des spécialités médicales si la liste est vie */
    disableComboMedicalSpeciality() {
      if (this.isModeEdition && this.medicalSpecialities.length <= 1) {
        return true;
      }
      return false;
    },

    /** Retourne l'état de possibilité de sauvegarde des datas du praticien */
    canSave() {
      // vue en chargement ou en cours d'action ?
      if (this.loading || this.running) {
        return false;
      }

      // Spécialité médicale demandée ?
      if (!this.checkMedicalSpeciality()) {
        logger.debug("cansave medical specialty uncompleted");
        return false;
      }

      // des changements ?
      if (!this.hasChanged) {
        logger.debug("no change");
        return false;
      }

      return true;
    },

    /** Vérifie si les datas du praticien ont changés */
    hasChanged() {
      let changed = false;

      if (this.practitioner && this.isModeEdition) {
        logger.debug("VERIF CHANGED");

        // Le préfixe de profession a changé ?
        if (
          this.practitionerSource.exerciseCivility !=
          this.exerciseCivility.value
        ) {
          logger.debug("Civility changed");
          changed = true;
        }
        // le genre
        let genderSource = convertPractitionerGender(
          this.practitionerSource.gender
        );
        if (genderSource != this.gender) {
          logger.debug("Gender changed");
          changed = true;
        }
        // le texte de présentation
        if (
          !areTheSame(this.practitionerSource.presentation, this.presentation)
        ) {
          logger.debug("Presentation changed");
          changed = true;
        }

        // la profession et/ou la spécialité médicale
        if (!changed) {
          changed = this.professionHasChanged;
          logger.debug("Profession has changed ? " + changed);
        }

        // Les lieux d''exercice
        if (!changed) {
          changed = this.practicePlacesHasChanged;
          logger.debug("PracticePlace has changed ? " + changed);
        }

        // Les agendas yesdoc
        if (!changed) {
          changed = this.yesdocAgendasHasCHanged;
          logger.debug("Agendas yesdoc has changed ? " + changed);
        }
      }

      return changed;
    },

    /** Vérifie que le nom de la profession ou la spécialité médicale n'est pas changé */
    professionHasChanged() {
      let changed = false;

      // le nom de la profession
      if (this.practitionerSource && this.selectedProfessionName) {
        if (
          !areTheSame(
            this.practitionerSource.professionName,
            this.selectedProfessionName.label
          )
        ) {
          logger.debug("profession changed");
          changed = true;
        }

        // la spécialité médicale
        // test si la source est nulle et la sélection existante ou le contraire
        if (
          (!this.practitionerSource.medicalSpecialty &&
            this.selectedMedicalSpeciality) ||
          (this.practitionerSource.medicalSpecialty &&
            !this.selectedMedicalSpeciality)
        ) {
          logger.debug("Médical spéciality before or after null");
          changed = true;
        } else if (
          this.practitionerSource.medicalSpecialty &&
          this.selectedMedicalSpeciality
        ) {
          // Si la spécialité médicale à changé
          if (
            this.practitionerSource.medicalSpecialty.code !=
            this.selectedMedicalSpeciality.code
          ) {
            logger.debug("Médical speciality changed");
            changed = true;
          }
        }
      }
      return changed;
    },

    // Vérifie que le/les lieux d'exercice n'ont pas changés
    practicePlacesHasChanged() {
      let changed = false;

      if (this.practitionerSource.practicePlaces) {
        // Première vérification pour plus de rapidité
        // si le nombre de la source n'est pas le même que celui de la vue, il y a eu des changements
        if (
          this.practitionerSource.practicePlaces.length !=
          this.practicesPlaces.length
        ) {
          logger.debug("number of practiceplace are not the same");
          return true;
        }

        // Parcours les lieu d'exercice visualisés pour trouver les nouveau ou les changements
        this.practicesPlaces.forEach((pa) => {
          // Tente de récupérer le lieu en cours dans la source
          let found = this.practitionerSource.practicePlaces.find(
            (pb) => pb.practicePlaceId == pa.practicePlaceId
          );

          // Si non trouvé, c'est un ajout
          if (!found) {
            changed = true;
          }
        });

        // Présence de changement, pas la peine de faire le reste
        if (changed) {
          return true;
        }

        // Parcours les lieu d'exercice source pour identifier s'il y a eu des suppressions
        this.practitionerSource.practicePlaces.forEach((pa) => {
          // Tente de récupérer le lieu en cours dans le visuel
          let found = this.practicesPlaces.find(
            (pb) => pb.practicePlaceId == pa.practicePlaceId
          );
          // Lieu d'exercice non trouvé, pas la peine de vérifier les agendas, ça été fait à la précédente aalyse
          if (!found) {
            logger.debug("practice place deleted");
            changed = true;
          }
        });
      }
      return changed;
    },

    // Vérifie si les agendas yesdoc ont changés
    yesdocAgendasHasCHanged() {
      let changed = false;

      if (this.practitionerSource.practicePlaces) {
        // Parcours des lieux d'exercices source pour en récupérer les agendas
        for (let pp of this.practitionerSource.practicePlaces) {
          // Recherche de l'objet agenda dans le tableau
          let foundAgendas = this.yesDocAgendas.find(
            (y) => y.practicePlaceId == pp.practicePlaceId
          );

          // On l'a trouvé ?
          if (foundAgendas) {
            // Dans la cas où la donnée agendas n'existait pas avant et qu'il y en as maintenant
            if (
              (!pp.agendaIds && foundAgendas.agendas.length > 0) ||
              (pp.agendaIds.length == 0 && foundAgendas.agendas.length > 0)
            ) {
              changed = true;
              break;
            }
            // Dans tous les autres cas
            else {
              // Parcours des agendas source pour trouver la correspondance
              for (let agendaSource of pp.agendaIds) {
                // Recherche de l'agenda dans les visuels
                let foundAgenda = foundAgendas.agendas.find(
                  (a) => a == agendaSource
                );

                if (!foundAgenda) {
                  changed = true;
                  break;
                }
              }

              // Parcours des agendas visuels pour trouver la correspondance dans la source
              for (let agenda of foundAgendas.agendas) {
                // Recherche de l'agenda dans la source
                let foundAgendaSource = pp.agendaIds.find((a) => a == agenda);

                if (!foundAgendaSource) {
                  changed = true;
                  break;
                }
              }
            }
          }
        }
      }
      return changed;
    },

    // Vérifie que la mode édition est actif
    isModeEdition() {
      return this.modeEdition;
    },

    // Vérifie si le praticien est un collaborateur
    practitionerIsCollaborater() {
      let result = false;

      if (this.practitioner) {
        if (this.practitioner.type == "COLLABORATER") {
          result = true;
        }
      }

      return result;
    },

    // Vérifie si le praticien est un externe mais non blacklisté
    practitionerIsExternal() {
      let result = false;

      if (this.practitioner) {
        if (
          !this.practitionerIsCollaborater &&
          !this.practitionerIsBlacklisted
        ) {
          result = true;
        }
      }
      return result;
    },

    // Vérifie si le praticien est un externe ET est blaclisté
    practitionerIsBlacklisted() {
      let result = false;

      if (this.practitioner) {
        if (!this.practitionerIsCollaborater && this.practitioner.blacklisted) {
          result = true;
        }
      }
      return result;
    },

    /** Retourne les lieux d'exercice disponibles pour le praticien */
    availablesPracticesPlaces() {
      let available = [];

      // Récupération de tous les lieux d'exercice que possede déjà la praticein
      let ids = this.practicesPlaces.map((p) => p.practicePlaceId);
      // Filtre tout les practice place non associé au praticien
      available = this.practicePlacesList.filter(
        (p) => !ids.includes(p.practicePlaceId)
      );

      return available;
    },

    /** Retourne l'autorisation d'ajout d'un practice place */
    canAddPracticePlace() {
      if (this.selectedPracticePlace) {
        return true;
      }
      return false;
    },

    /** Retourne l'autorisation d'ajout d'un agenda yesdoc */
    canAddYesdocAgenda() {
      if (this.yesdocAgendaToAdd) {
        return true;
      }
      return false;
    },

    /** Retourne la visibilité du bouton de suppression d'un lieu d'exercice */
    viewDeletePracticePlace() {
      // Un praticien doit toujours posséder un lieu d'exercice
      if (this.practicesPlaces.length <= 1) {
        return false;
      }
      // N"est visible qu"en mode édition
      if (!this.isModeEdition) {
        return false;
      }

      return true;
    },

    /** Retourne la visibilité du bouton de modification */
    viewModifButton() {
      let view = true;

      // n'apparait pas si l'on as pas les droits
      if (!this.canEditRole) {
        view = false;
      }
      // n'apparait pas s'il n'y a pas de praticien sélectionné
      if (!this.practitioner) {
        view = false;
      }
      // n'apparait pas si on est en mode édition
      if (this.modeEdition) {
        view = false;
      }
      // n'apparait pas si le praticien n'est pas un collaborateur
      if (!this.practitionerIsCollaborater) {
        view = false;
      }

      return view;
    },

    /** Retourne la visibilité du message de nom présence du compte gsuite */
    viewMessagePractitionerHasNotAccount() {
      if (this.practitionerIsCollaborater) {
        if (this.collaboratorPractitionner) {
          if (!this.collaboratorPractitionner.account) {
            return true;
          }
        }
      }
      return false;
    },
  },

  watch: {
    /** Méthode de surveillance de saisie de recherche du praticien */
    search(value) {
      // Recherche incrémentale du praticien
      this.findPractitioner(value);
    },
  },

  mounted() {
    // Url de base du site Avec
    this.baseUrlAvec = process.env.VUE_APP_BASE_SITE_AVEC;

    // Configure les éléments si on peut éditer les rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.servicePractitioner = new PractitionnerService(
      this.$api.getPractitionerApi()
    );
    this.serviceCollaboratorPractitioner = new CollaboratorPractitionerService(
      this.$api.getCollaboratorPractitionerApi()
    );
    this.serviceMedicalSpecialties = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );
    this.serviceCollaborators = new CollaboratorsService(
      this.$api.getCollaboratorsApi()
    );
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceEstablishments = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);

    this.load();
  },
};
</script>

<style>
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
